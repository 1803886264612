import React, { FC } from 'react';
import Layout from 'containers/layout/layout';
import SEO from 'components/seo';
import ErrorArea from 'containers/error-area';
import Header from 'containers/layout/header';

interface Props {
  location: any;
}

const NotFoundPage: FC<Props> = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <Header fixedDisplayVarient={true} />
    <ErrorArea />
  </Layout>
);

export default NotFoundPage;
