import React from 'react';
import { Container, Row, Col } from 'components/ui/wrapper';
import Button from 'components/ui/button';
import { ErrorWrap } from './error-area.style';
import imageSlice404 from 'assets/images/ATB-404-Slice.jpg';

const ErroArea = () => {
  return (
    <ErrorWrap className="top-right-shadow-corner">
      <Container>
        <Row>
          <Col xl={7} lg={8} ml="auto" mr="auto">
            <img src={imageSlice404} />
            <h2>Whoops! This page doesn’t exist, so sorry!</h2>
            <Button varient="texted" to={'/'}>
              Go to our Homepage
            </Button>
          </Col>
        </Row>
      </Container>
    </ErrorWrap>
  );
};

export default ErroArea;
